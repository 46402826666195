import React from "react"
import Layout from "../components/layout"

const WhatPage = () => (
  <Layout>
    <h1>What is Continuous Coaching?</h1>
    <ul>
      <li>ContinuousCoaching is a suite of web based and mobile tools</li>
      <li>You can customize it to fit your branding</li>
      <li>You use it to investigate what’s up</li>
      <li>as a means of guiding coaching or training activities</li>
      <li>as a means of assessing skills or learning retention</li>
      <li>as a means of gauging compliance</li>
      <li>
        Unlike other tools, investigations are individually actionable, not anonymous nor reduced to only
        statistics
      </li>
    </ul>
  </Layout>
)

export default WhatPage
